.form__col {
  margin: var(--header-height) auto;
  padding-top: 20px;
  width: 800px;

  &__header {
    font: normal normal 400 25px Rany;
  }

  &__inputWrapper {
    margin-top: 20px;
    border: 1px solid black;
    min-height: 300px;
    padding: 30px 50px;
    box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);

    &__con {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }

  &__inputCon {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    &__amount {
      position: relative;
      & span {
        position: absolute;
        left: 0;

        width: 30px;
        height: 100%;
        background-color: #1eca245f;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        & p {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }

    & input {
      height: 35px;
      border: 1px solid black;
      // border-radius: 5px;
      padding: 0 20px;
      transition: 0.3s;

      &:focus {
        outline: none;
        border: 1px solid var(--secondary-color);
      }
      &:hover {
        border: 1px solid var(--secondary-color);
      }
    }

    & select {
      height: 40px;
      border: 1px solid black;
      // border-radius: 5px;
      transition: 0.3s;

      &:focus {
        outline: none;
        border: 1px solid var(--secondary-color);
      }
      &:hover {
        border: 1px solid var(--secondary-color);
      }
    }

    & label {
      margin-bottom: 2px;
    }
  }

  &__btnCon {
    display: flex;
    justify-content: center;

    & button {
      width: 300px;
      height: 40px;
      border: none;
      outline: none;
      // border-radius: 5px;
      color: white;
      background-color: var(--primary-color);
      cursor: pointer;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 850px) {
  .form__col {
    width: 350px;
    &__inputWrapper {
      padding: 30px 20px;

      &__con {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .form__col__con {
    padding: 20px;
  }
  .form__col {
    width: 100%;
    &__inputWrapper {
      padding: 30px 20px;

      &__con {
        grid-template-columns: 1fr;
      }
    }
  }
}
