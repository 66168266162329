:root {
  --header-height: 70px;
  /*========== Colors ==========*/
  --primary-color: #0d7731;
  --secondary-color: #1eca23;
  --error-color: #e55c5c;
  --body-color: #fff;
  --text-color: #000000;

  /*========== font ==========*/
  --body-font: "Rany", sans-serif;

  /*========== z-index ==========*/
  --z-fixed: 10;
  --z-modal: 110;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
}

h1,
h2,
h3,
ul,
p {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.error-message {
  color: var(--error-color);
  font-size: 13px;
}

/*=============== Font ==============*/

@font-face {
  font-family: "Rany";
  src: url("./font/rany-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rany";
  src: url("./font/rany-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Rany";
  src: url("./font/rany-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rany";
  src: url("./font/rany-medium-webfont.woff") format("woff");
  font-weight: 600;
  font-style: medium;
}

@font-face {
  font-family: "Thunder";
  src: url("./font/Thunder-BlackHC.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Thunder";
  src: url("./font/Thunder-BoldHC.otf");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Thunder";
  src: url("./font/Thunder-ExtraBoldHC.otf");
  font-weight: 800;
  font-style: bold;
}

@font-face {
  font-family: "Thunder";
  src: url("./font/Thunder-MediumHC.otf");
  font-weight: 600;
  font-style: medium;
}

@font-face {
  font-family: "Thunder";
  src: url("./font/Thunder-SemiBoldHC.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Thunder";
  src: url("./font/Thunder-ThinHC.otf");
  font-weight: 300;
  font-style: normal;
}

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

@media screen and (max-width: 400px) {
  .captcha {
    transform: scale(0.77);
  }
}
