.login {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    width: 100%;

    & img {
      height: 50px;
    }
  }

  &__main {
    border: 1px solid black;
    box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    width: 500px;
    margin-top: 150px;
    // border-radius: 10px;
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      text-align: center;
      margin-bottom: 20px;
    }

    &__inputs {
      margin-bottom: 20px;
      width: 100%;

      &__item {
        display: flex;
        flex-direction: column;

        &__disc {
          font-size: 12px;
          text-align: center;
          margin-bottom: 20px;
        }

        & input {
          height: 35px;
          border: 1px solid black;
          padding: 0 20px;
          transition: 0.3s;

          &:focus {
            outline: none;
            border: 1px solid var(--secondary-color) !important;
          }
          &:hover {
            border: 1px solid var(--secondary-color) !important;
          }
        }
      }
    }

    &__btn {
      width: 150px;
      height: 35px;
      border: 1px solid var(--primary-color);
      outline: none;
      color: #fff;
      background-color: var(--primary-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 550px) {
  .login {
    &__main {
      width: 100%;
      padding: 40px 20px;
    }
  }
}
