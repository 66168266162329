.home {
  height: 100vh;
  position: relative;

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 30px);

    &__left {
      border: 1px solid #9d9d9d;
      border-top: none;
      border-left: none;
      padding: 0px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: url(../images/birs-blur.png), 0% 0% no-repeat padding-box;
      background-size: cover;
      background-position: center;

      &__logo {
        height: 50px;
        margin-bottom: 5%;
      }

      &__img {
        margin-bottom: 5%;
        height: 200px;
      }

      & div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__details {
        & h1 {
          width: 450px;
          text-align: center;
          font: normal normal bold 60px/65px Thunder;
          margin-bottom: 3%;
        }
        & p {
          width: 400px;
          text-align: center;
          font: normal normal 300 15px/20px Rany;
        }
      }
    }

    &__right {
      &__support {
        height: 30px;
        text-align: right;
        padding: 0 30px;
        font: normal normal 600 13px/20px Rany;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        display: none;
        & a {
          margin-left: 5px;
          color: black;
          text-decoration: underline;
          font: normal normal 300 13px/20px Rany;
        }
      }

      &__payment {
        height: calc(25%);
        border-top: 1px solid #9d9d9d;
        border-left: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 30px;

        &:first-child {
          border-top: none !important;
        }
        &:last-child {
          border-bottom: 1px solid #9d9d9d;
        }

        &__details {
          height: calc(100% - 100px);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          & h2 {
            font: normal normal 300 25px/50px Rany;
          }
        }

        &__link {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          & span {
            display: flex;
            align-items: center;
            color: var(--secondary-color);
            cursor: pointer;
            font-size: 25px;
            font-weight: 500;

            & img {
              transform: scale(1.3);
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    align-items: center;
    height: 30px;

    & span {
      font: normal normal 600 12px Rany;
    }
  }
}

@media screen and (max-width: 850px) {
  .home {
    height: auto;
    &__main {
      grid-template-columns: 1fr;
      height: auto;

      &__left {
        padding: 30px 0;
        border-right: none;
        border-bottom: none;
      }

      &__right {
        &__payment {
          height: auto;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      align-items: center;
      height: 30px;

      & span {
        font: normal normal 600 12px Rany;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .home {
    &__main {
      grid-template-columns: 1fr;
      height: auto;

      &__left {
        padding: 30px 15px;

        &__details {
          & h1 {
            width: 100%;
            font: normal normal bold 50px Thunder;
          }
          & p {
            width: 100%;
            font: normal normal 300 15px Rany;
          }
        }
      }

      &__right {
        &__payment {
          padding: 15px;
          &__details {
            & h2 {
              font: normal normal 300 22px/50px Rany;
            }
          }
          &__link {
            & span {
              font-size: 22px;
              font-weight: 500;

              & img {
                transform: scale(1.1);
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    &__footer {
      padding: 5px 15px;
      height: auto;

      & span {
        font: normal normal 600 11px Rany;

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }
}
