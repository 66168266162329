.paymentSuccess {
  height: calc(100vh - 30px);
  padding-top: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__main {
    border: 1px solid #000000;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);

    &__details {
      font: normal normal 300 20px/20px Rany;
      padding: 20px;
      text-align: center;
    }

    &__btnCon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__btnCon button {
      width: 200px;
      height: 40px;
      border: 2px solid #000;
      outline: none;
      background-color: #fff;
      cursor: pointer;
    }
  }
}
