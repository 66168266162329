.modal {
  &__backdrop {
    background: rgba(0, 0, 0, 0.65);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--z-modal);
    display: flex;
    align-items: center;
  }

  &__container {
    background: #fff;
    border-radius: 5px;
    max-width: 80%;
    margin: 50px auto;
    padding: 30px;
    width: 500px;
    position: relative;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center;
  }

  &__img {
    width: 20px;
  }
  &__img__con {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
