.notFound {
  height: calc(100vh - 30px);
  padding-top: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h1 {
    font: normal normal normal 20px/20px Rany;
    margin: 30px 0;
  }

  & p {
    font: normal normal normal 15px/20px Rany;
    width: 400px;
    text-align: center;
  }

  &__btn {
    width: 200px;
    height: 40px;
    border: none;
    outline: none;
    color: white;
    background-color: var(--primary-color);
    cursor: pointer;
    margin-top: 20px;
  }
}
