.formNav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
  display: flex;
  align-items: center;
  padding: 10px 100px;

  &__logo {
    height: 45px;
  }
}
