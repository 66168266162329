.invoicePayment {
  margin: var(--header-height) auto;
  padding-top: 20px;
  width: 500px;

  &__header {
    font: normal normal 400 25px Rany;
  }

  &__inputWrapper {
    margin-top: 20px;
    border: 1px solid black;
    padding: 30px 50px;
    box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 15px 15px 1px 0px rgba(0, 0, 0, 0.06);
  }

  &__inputCon {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    &__balance {
      position: relative;
      & input {
        height: 35px;
        border: 1px solid black;
        padding: 0 20px;
        transition: 0.3s;

        &:focus {
          outline: none;
          border: 1px solid var(--secondary-color);
        }
        &:hover {
          border: 1px solid var(--secondary-color);
        }
      }
      & span {
        position: absolute;
        left: 0;

        width: 30px;
        height: 100%;
        background-color: #1eca245f;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        & p {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }

    & input {
      height: 35px;
      border: 1px solid black;
      padding: 0 20px;
      transition: 0.3s;

      &:focus {
        outline: none;
        border: 1px solid var(--secondary-color);
      }
      &:hover {
        border: 1px solid var(--secondary-color);
      }
    }

    &__remittance {
      display: grid;
      grid-template-columns: 2fr 1fr;

      & button {
        margin-left: 20px;
        height: 35px;
        border: 1px solid var(--secondary-color);
        outline: none;
        color: var(--secondary-color);
        background-color: #fff;
        cursor: pointer;
      }
    }

    & label {
      margin-bottom: 2px;
    }
  }

  &__detail {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;

    & b {
      font-weight: 600;
    }
  }

  &__detail__con {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }

  &__btnCon {
    display: flex;
    justify-content: center;

    & button {
      width: 200px;
      height: 40px;
      border: none;
      outline: none;
      color: white;
      background-color: var(--primary-color);
      cursor: pointer;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  .invoicePayment {
    margin: var(--header-height) auto;
    padding-top: 20px;
    width: 350px;
  }
}

@media screen and (max-width: 400px) {
  .invoicePayment__con {
    padding: 20px;
  }
  .invoicePayment {
    margin: var(--header-height) 0;
    padding-top: 20px;
    width: 100%;

    &__inputWrapper {
      padding: 30px 20px;
    }
  }

  .invoicePayment__inputCon__remittance {
    grid-template-columns: 1fr;
    & button {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
